import type { TransitionConfig } from "svelte/transition";

const config: TransitionConfig = {
  css: (_t, u) => `right: -${u * 100}%;`,
  delay: 0,
  duration: 300,
  easing: (t: number): number =>
    // linear cubic bezier function conversion
    (1 - t) ** 2 * t * 3 * 0.3 + (1 - t) * t ** 2 * 3 + t ** 3,
};

export const slide = (_: unknown): TransitionConfig => config;
