<svelte:options immutable />

<script context="module" lang="ts">
  import CiscoSpacesSvg from "../icons/cisco-spaces-svg.svelte";
  import IntersightSvg from "../icons/intersight-svg.svelte";
  import IotSVG from "../icons/iot-svg.svelte";
  import MerakiSvg from "../icons/meraki-svg.svelte";
  import ThousandEyesSvg from "../icons/thousand-eyes-svg.svelte";
  import type { Link } from "../links";
  import type { ComponentType } from "svelte";

  const map: Readonly<Record<Link["platformId"], ComponentType>> = {
    "cisco-spaces": CiscoSpacesSvg,
    intersight: IntersightSvg,
    "iot-operations-dashboard": IotSVG,
    meraki: MerakiSvg,
    "thousand-eyes": ThousandEyesSvg,
  };
</script>

<script lang="ts" strictEvents>
  export let platformId: Link["platformId"];

  $: component = map[platformId];
</script>

<span>
  <svelte:component this={component} />
</span>

<style>
  span {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 6px;

    /* not a token on purpose */
    background: #f0f1f2;
    border-radius: 8px;
  }
</style>
