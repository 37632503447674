<svelte:options immutable />

<script lang="ts" strictEvents>
  import PanelClose from "./panel-close-button.svelte";

  export let labelledById: string;
</script>

<header>
  <h2 id={labelledById}>Cisco Networking Cloud</h2>
  <PanelClose on:close-panel />
</header>

<style>
  header {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: space-between;

    padding: 24px 24px 16px;
  }

  h2 {
    margin: 0;
    font:
      normal 700 18px / 24px "Sharp Sans",
      -apple-system,
      blinkmacsystemfont,
      "Segoe UI",
      roboto,
      sans-serif;
    color: var(--default-text-strong, #373c42);
  }
</style>
