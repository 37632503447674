<script lang="ts" strictEvents>
  import { links } from "../links";
  import Link from "./panel-link.svelte";

  export let source: string;
</script>

<ul aria-label="Navigation links">
  {#each links as link (link.platformId)}
    <li>
      <Link {...link} {source} on:xpn-navigate />
    </li>
  {/each}
</ul>

<style>
  ul {
    overflow: scroll;
    margin: -4px;
    padding: 4px;
    list-style-type: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  ul::-webkit-scrollbar {
    display: none;
  }
</style>
