<svelte:options immutable />

<script lang="ts" strictEvents>
  import WaffleSvg from "../icons/waffle-svg.svelte";

  export let open: boolean;

  $: label = `${open ? "Close" : "Open"} cross platform navigator`;
</script>

<button class:open aria-label={label} type="button" on:click>
  <WaffleSvg />
</button>

<style>
  button {
    cursor: pointer;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    padding: 6px;

    color: var(--icon-inverse, #f7f7f7);

    background-color: transparent;
    border: none;
    border-radius: 8px;

    transition:
      all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
      box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  }

  button:hover,
  button.open {
    color: var(--inverse-icon-hover, #fff);
    background-color: rgb(0 0 0 / 20%);
  }

  button:focus-visible {
    outline: none;
    box-shadow:
      0 0 0 2px var(--interact-bg-weak-active, #cce1ff),
      0 0 0 4px var(--control-border-focus, #3e84e5);
  }
</style>
