<svg
  aria-hidden="true"
  fill="none"
  height="24"
  viewBox="0 0 24 24"
  width="24"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M4.625 7.125a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm7.375 0a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm7.375 0a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM4.625 14.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm7.375 0a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm7.375 0a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm-14.75 7.375a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm7.375 0a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm7.375 0a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
    fill="currentColor"
  />
</svg>
