<svg
  aria-hidden="true"
  fill="none"
  height="24"
  role="img"
  viewBox="0 0 24 24"
  width="24"
  xmlns="http://www.w3.org/2000/svg"
>
  <title>cisco spaces logo</title>
  <g clip-path="url(#spaces-a)">
    <path
      d="M13651-3812H-3977c-265.1 0-480 214.9-480 480v4583c0 265.1 214.9 480 480 480h17628c265.1 0 480-214.9 480-480v-4583c0-265.1-214.9-480-480-480Z"
      stroke="url(#spaces-b)"
      stroke-width="40"
    />
    <mask
      id="spaces-c"
      style:mask-type="luminance"
      height="24"
      maskUnits="userSpaceOnUse"
      width="16"
      x="4"
      y="0"
    >
      <path d="M20 0H4v24h16V0Z" fill="#fff" />
    </mask>
    <g clip-rule="evenodd" fill-rule="evenodd" mask="url(#spaces-c)">
      <path
        d="M18.927 12a7.996 7.996 0 0 1-6.93 4 7.997 7.997 0 0 1-6.93-4 7.996 7.996 0 0 1 6.928-4 8 8 0 0 1 6.932 4Z"
        fill="url(#spaces-d)"
        opacity=".25"
      />
      <path
        d="M5.02 19.909A7.998 7.998 0 0 1 4 15.998h8A8.001 8.001 0 0 0 18.929 12a7.986 7.986 0 0 1 1.047 4.616 8.012 8.012 0 0 1-1.32 3.82 8 8 0 0 1-13.636-.527Z"
        fill="url(#spaces-e)"
      />
      <path
        d="M18.98 4.089A8.01 8.01 0 0 1 20 7.999h-8a8.001 8.001 0 0 0-6.929 3.999 7.986 7.986 0 0 1-1.047-4.616 8.012 8.012 0 0 1 1.32-3.82 7.999 7.999 0 0 1 13.636.527Z"
        fill="url(#spaces-f)"
      />
    </g>
  </g>
  <defs>
    <linearGradient
      id="spaces-b"
      gradientUnits="userSpaceOnUse"
      x1="-4392.92"
      x2="14150.7"
      y1="-2635.64"
      y2="-2708.78"
    >
      <stop offset=".052" stop-color="#53A828" />
      <stop offset=".938" stop-color="#20AB4E" />
    </linearGradient>
    <linearGradient
      id="spaces-d"
      gradientUnits="userSpaceOnUse"
      x1="5.07"
      x2="18.933"
      y1="12.144"
      y2="12.144"
    >
      <stop stop-color="#30D557" />
      <stop offset="1" stop-color="#00BCEB" />
    </linearGradient>
    <linearGradient
      id="spaces-e"
      gradientUnits="userSpaceOnUse"
      x1="4"
      x2="20"
      y1="17.999"
      y2="17.999"
    >
      <stop stop-color="#00BCEB" />
      <stop offset="1" stop-color="#00BCEB" stop-opacity=".28" />
    </linearGradient>
    <linearGradient
      id="spaces-f"
      gradientUnits="userSpaceOnUse"
      x1="19.99"
      x2="2.145"
      y1="5.942"
      y2="6.031"
    >
      <stop stop-color="#30D557" />
      <stop offset="1" stop-color="#30D557" stop-opacity="0" />
    </linearGradient>
    <clipPath id="spaces-a">
      <path d="M0 0h24v24H0z" fill="#fff" />
    </clipPath>
  </defs>
</svg>
