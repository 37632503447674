<svg
  aria-hidden="true"
  fill="none"
  height="24"
  viewBox="0 0 24 24"
  width="24"
  xmlns="http://www.w3.org/2000/svg"
>
  <title>iot operations dashboard logo</title>
  <g clip-path="url(#iot-a)">
    <circle cx="12" cy="12" fill="#07BCEB" r="12" />
    <path
      clip-rule="evenodd"
      d="M6.216 7.704H4.875v8.016h1.341V7.704Zm13.616 1.223V7.704h-6.536v1.223h2.581v6.793h1.342V8.927h2.613ZM15.997 15.6h1.102V8.807h2.613v-.983.983H17.1V15.6h-1.102Zm0-6.793h-2.581 2.581Zm-5.883.895c-.787 0-1.513.246-2.043.762-.531.517-.853 1.293-.853 2.328 0 .997.308 1.772.83 2.299.521.526 1.245.791 2.055.791.819 0 1.546-.26 2.068-.783.522-.524.827-1.3.827-2.307 0-1.035-.316-1.81-.843-2.328-.526-.516-1.25-.762-2.041-.762Zm.01 5.098c-.454 0-.846-.17-1.126-.5-.282-.33-.46-.832-.46-1.508 0-.682.178-1.183.459-1.512.278-.328.668-.495 1.117-.495.45 0 .835.163 1.11.486.278.326.454.827.454 1.521 0 .679-.166 1.18-.437 1.51-.269.327-.652.498-1.117.498Zm-4.028.8V7.824 15.6H4.995h1.101Zm6.76-3.262c.015.145.022.296.022.454 0 1.966-1.188 2.97-2.775 2.97-1.436 0-2.564-.863-2.74-2.505.176 1.642 1.304 2.505 2.74 2.505 1.587 0 2.775-1.004 2.775-2.97 0-.158-.007-.309-.022-.454Zm-4.418.84a3.572 3.572 0 0 1-.02-.386c0-1.404.734-2.127 1.696-2.127.855 0 1.523.556 1.659 1.685-.136-1.129-.803-1.686-1.66-1.686-.96 0-1.695.724-1.695 2.128 0 .135.007.263.02.386Z"
      fill="#004F75"
      fill-rule="evenodd"
    />
  </g>
  <defs>
    <clipPath id="iot-a">
      <path d="M0 0h24v24H0z" fill="#fff" />
    </clipPath>
  </defs>
</svg>
