<svg
  aria-hidden="true"
  fill="none"
  height="16"
  viewBox="0 0 16 16"
  width="16"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    clip-rule="evenodd"
    d="M13.03 2.97a.75.75 0 0 1 0 1.06l-9 9a.75.75 0 0 1-1.06-1.06l9-9a.75.75 0 0 1 1.06 0Z"
    fill="currentColor"
    fill-rule="evenodd"
  />
  <path
    clip-rule="evenodd"
    d="M2.97 2.97a.75.75 0 0 1 1.06 0l9 9a.75.75 0 1 1-1.06 1.06l-9-9a.75.75 0 0 1 0-1.06Z"
    fill="currentColor"
    fill-rule="evenodd"
  />
</svg>
