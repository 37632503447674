<svg
  aria-hidden="true"
  fill="none"
  height="24"
  viewBox="0 0 24 24"
  width="24"
  xmlns="http://www.w3.org/2000/svg"
>
  <title>intersight logo</title>
  <path
    clip-rule="evenodd"
    d="M10.08 10.56a1.92 1.92 0 1 0 3.84 0 1.92 1.92 0 0 0-3.84 0Zm3.84 5.76a1.92 1.92 0 1 0-3.84 0v5.76a1.92 1.92 0 1 0 3.84 0v-5.76Z"
    fill="#363C52"
    fill-rule="evenodd"
  />
  <path
    clip-rule="evenodd"
    d="M14.676 4.324a7.679 7.679 0 0 0-8.161 12.574 1.92 1.92 0 0 1-2.744 2.685A11.517 11.517 0 0 1 1.204 7.506C2.933 2.858 7.344-.01 12.03 0c1.325.003 2.671.237 3.986.725 5.962 2.218 8.997 8.85 6.779 14.814-.57 1.53-1.444 2.9-2.565 4.043a1.92 1.92 0 1 1-2.741-2.688 7.68 7.68 0 0 0-2.812-12.57Z"
    fill="#29B6F6"
    fill-rule="evenodd"
  />
</svg>
