<svg
  aria-hidden="true"
  fill="none"
  height="24"
  role="img"
  viewBox="0 0 24 24"
  width="24"
  xmlns="http://www.w3.org/2000/svg"
>
  <title>meraki logo</title>
  <g clip-path="url(#meraki-a)">
    <path
      d="M20 0H4a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4Z"
      fill="#5DAB47"
    />
    <path
      d="M5.7 17.637a1.894 1.894 0 0 1-1.895-1.895V8.256a1.895 1.895 0 1 1 3.79 0v7.487a1.895 1.895 0 0 1-1.896 1.894ZM12.727 17.638a1.896 1.896 0 0 1-1.713-1.085l-2.544-5.38a1.896 1.896 0 0 1 3.43-1.62l2.544 5.382a1.897 1.897 0 0 1-.902 2.526c-.255.12-.533.18-.815.177ZM18.302 17.637a1.894 1.894 0 0 1-1.711-1.085l-2.546-5.381a1.895 1.895 0 0 1 3.424-1.619l2.543 5.382a1.896 1.896 0 0 1-.901 2.526c-.253.118-.53.179-.809.177Z"
      fill="#fff"
    />
  </g>
  <defs>
    <clipPath id="meraki-a">
      <path d="M0 0h24v24H0z" fill="#fff" />
    </clipPath>
  </defs>
</svg>
