<svelte:options immutable />

<script lang="ts" strictEvents>
  import { slide } from "../slide";
  import PanelHeader from "./panel-header.svelte";
  import PanelList from "./panel-list.svelte";

  export let source: string;
  const labelledById = "xpn-panel-header";
</script>

<nav aria-labelledby={labelledById} transition:slide>
  <PanelHeader {labelledById} on:close-panel />
  <PanelList {source} on:xpn-navigate />
</nav>

<style>
  nav {
    position: absolute;
    top: var(--panel-offset);
    right: 0;

    display: flex;
    flex-direction: column;

    min-width: 340px;
    height: calc(100vh - var(--panel-offset));

    background-color: var(--control-bg-weak, #fff);
    box-shadow: 0 4px 12px rgb(0 0 0 / 12%);
  }
</style>
