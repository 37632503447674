<svelte:options immutable />

<script lang="ts" strictEvents>
  import { createEventDispatcher } from "svelte";
  import CloseSvg from "../icons/close-svg.svelte";

  const dispatch = createEventDispatcher<{ "close-panel": undefined }>();

  const closePanel = () => dispatch("close-panel");

  const closePanelOnEscape = ({ key }: KeyboardEvent) => {
    if (key === "Escape") {
      closePanel();
    }
  };
</script>

<svelte:window on:keydown={closePanelOnEscape} />

<button
  aria-label="Close cross platform navigator"
  type="button"
  on:click={closePanel}
>
  <CloseSvg />
</button>

<style>
  button {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 4px;

    color: var(--interact-icon-weak, #7e868f);

    background: transparent;
    border: 0;
    border-radius: 6px;

    transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  }

  button:hover {
    color: var(--interact-icon-weak-hover, #6f7680);
    background-color: var(--interact-bg-weak-hover, #cce1ff);
  }

  button:focus-visible {
    outline: none;
    box-shadow:
      0 0 0 2px var(--interact-border-weak-hover, #cce1ff),
      0 0 0 4px var(--control-border-hover, #3e84e5);
  }
</style>
