<svelte:options immutable />

<span><slot /></span>

<style>
  span {
    font:
      normal 400 14px / 20px Inter,
      -apple-system,
      blinkmacsystemfont,
      "Segoe UI",
      roboto,
      sans-serif;
    color: var(--default-text, #23282e);
  }
</style>
