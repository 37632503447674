<svg
  aria-hidden="true"
  fill="none"
  height="24"
  viewBox="0 0 24 24"
  width="24"
  xmlns="http://www.w3.org/2000/svg"
>
  <title>thousandeyes logo</title>
  <g clip-path="url(#1ke-a)">
    <path
      d="M6.4 11.9c1 1.4 3.9 4.6 7.5 4.6 2.5 0 5-1.6 7.4-4.6-2.4-3-4.9-4.5-7.3-4.5-3.7 0-6.5 3.2-7.6 4.5Zm7.5 6.8c-5.7 0-9.5-5.9-9.7-6.2l-.4-.6.4-.6c.1-.2 4-6 9.7-6 3.4 0 6.6 2 9.6 6l.5.6-.5.6c-3.1 4.1-6.3 6.2-9.6 6.2Z"
      fill="#FF671D"
    />
    <path
      d="M9.6 18.7c-5.5-.2-9.2-6-9.3-6.2l-.3-.6.3-.6c.1-.2 3.7-5.8 9.2-6-4.2 2-7.2 6.7-7.2 6.7a20 20 0 0 0 7.3 6.7ZM13 15.4a14.998 14.998 0 0 0 4.4-3.5 14.83 14.83 0 0 0-4.5-3.4C16.2 7.8 19 11 19 11l.8.8-.8.9c-3 3.4-6 2.7-6 2.7Z"
      fill="#FF671D"
    />
    <path
      d="M13.3 12a1.5 1.5 0 1 1-1.5-1.5 1.54 1.54 0 0 1 1.5 1.5Z"
      fill="#244B5A"
    />
  </g>
  <defs>
    <clipPath id="1ke-a">
      <path d="M0 0h24v24H0z" fill="#fff" />
    </clipPath>
  </defs>
</svg>
