<svelte:options
  customElement={{
    props: {
      sourceProperty: { attribute: "source", type: "String" },
    },
    tag: "cross-platform-navigator",
  }}
  immutable
/>

<script lang="ts" strictEvents>
  import { createEventDispatcher } from "svelte";
  import Panel from "./components/panel.svelte";
  import Toggle from "./components/toggle.svelte";
  import type { ToggleDetail, ToggleEvent } from "./tracking";

  const dispatch = createEventDispatcher<Record<ToggleEvent, ToggleDetail>>();

  // eslint-disable-next-line unicorn/no-useless-undefined -- setting equal to undefined makes the prop optional
  export let sourceProperty: string | undefined = undefined;

  let open = false;

  $: source = sourceProperty || "true";
  $: dispatch("xpn-toggle", { open });

  const togglePanel = () => {
    open = !open;
  };

  const closePanel = () => {
    open = false;
  };
</script>

<Toggle {open} on:click={togglePanel} />

<!-- /* c8 ignore next */ -->
{#if open}
  <Panel {source} on:close-panel={closePanel} on:xpn-navigate />
{/if}
