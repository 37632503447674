<svelte:options immutable />

<script lang="ts" strictEvents>
  import { createEventDispatcher } from "svelte";
  import Content from "./panel-link-content.svelte";
  import Icon from "./panel-link-icon.svelte";
  import type { Link } from "../links";
  import type { NavigateDetail, NavigateEvent } from "../tracking";

  const dispatch =
    createEventDispatcher<Record<NavigateEvent, NavigateDetail>>();

  export let platformId: Link["platformId"];
  export let source: string;
  export let textContent: Link["textContent"];
  export let url: Link["url"];

  const navigate = () =>
    dispatch("xpn-navigate", {
      platformId,
      source,
      textContent,
      url,
    });

  $: href = `${url}?from_platform_navigator=${source}`;
</script>

<a {href} rel="noopener noreferrer" target="_blank" on:click={navigate}>
  <Icon {platformId} />
  <Content>{textContent}</Content>
</a>

<style>
  a {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;

    padding: 8px 24px;

    text-decoration: none;

    transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  }

  a:focus-visible {
    outline: none;
    box-shadow:
      0 0 0 2px var(--interact-bg-weak-active, #cce1ff),
      0 0 0 4px var(--control-border-focus, #3e84e5);
  }

  a:hover {
    background: var(--control-bg-hover, #e3eeff);
  }
</style>
