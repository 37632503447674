export type Platform =
  | "intersight"
  | "iot-operations-dashboard"
  | "meraki"
  | "cisco-spaces"
  | "thousand-eyes";
export type Link = {
  platformId: Platform;
  textContent: string;
  url: string;
};
export const links: readonly Link[] = [
  {
    platformId: "cisco-spaces",
    textContent: "Cisco Spaces",
    url: "https://dnaspaces.io/",
  },
  {
    platformId: "intersight",
    textContent: "Intersight",
    url: "https://intersight.com/",
  },
  {
    platformId: "iot-operations-dashboard",
    textContent: "IoT Operations Dashboard",
    url: "https://us.ciscoiot.com/",
  },
  {
    platformId: "meraki",
    textContent: "Meraki",
    url: "https://dashboard.meraki.com/",
  },
  {
    platformId: "thousand-eyes",
    textContent: "ThousandEyes",
    url: "https://app.thousandeyes.com/",
  },
];
